import { Flex, Box, VStack, Text, Link, useBreakpointValue, Img } from "@chakra-ui/react";
import { motion } from "framer-motion";
import CustomButton from "../CustomButton";
import {BtShow} from "../../core/types";
import {Fragment} from "react";
import {buildImageUrl, date2date, date2time} from "../../core/helpers";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

interface ShowCardProps {
    show: BtShow;
}

const MotionImage = motion(Box);
const MotionText = motion(Text);
const MotionLink = motion(Link);
const MotionBox = motion(Box);

const fadeInAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.5, duration:3 } },
};

const translateYAnimation = (index: number) => ({
  initial: { opacity: 0, y: 200 },
  animate: { opacity: 1, y: 0, transition: { delay: 0.5 + index * 0.1, duration: 0.5 } },
});

const translateXAnimation = (index: number) => ({
  initial: { opacity: 0, x: 400 },
  animate: { opacity: 1, x: 0, transition: { delay: 0.5 + index * 0.1, duration: 0.5 } },
});

function ShowCard({show} :ShowCardProps) {
    const totalRepresentations = show.rooms.reduce((total, room) => total + room.representations.length, 0);
    const navigate = useNavigate();

    const layout = useBreakpointValue({ base: "mobile", md: "desktop" });
    const { t, i18n } = useTranslation();
    return  layout === "mobile" ? (
        <Box
          borderRadius={7}
          width={'100%'} 
          p={5} 
          overflowY="auto" 
          height='400' 
          position="relative"
          css={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
          }}
          backgroundImage={`linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 5%, rgba(255,255,255,0) 56%,rgba(255,255,255,0) 100%), url(${buildImageUrl(show.onlineAvatar, 'm')})`}>
          <VStack mb={4} align={'center'} width={'100%'} color={'#f0e6d9'}>
              <MotionText  key={1}  p={2} fontSize='2xl' fontWeight={'bold'} noOfLines={2} {...translateYAnimation(1)} fontFamily={'Raleway'}>
                  {i18n.language === "ro" ? show.name.toUpperCase(): show.nameEng.toUpperCase()}
              </MotionText >
              <MotionText fontWeight={'bold'} fontSize='xs' noOfLines={2} key={4} {...translateYAnimation(4)} fontFamily={'Roboto Condensed'}>
                {show.warning && show.warning}
                {!show.warning && t("SHOW_LENGTH") + ':' + show.duration}
              </MotionText >
          </VStack>
          {show.rooms.filter(r => r.representations.length > 0).map((r) => (
              <Fragment key={r.id}>
                  <Text width={'100%'} textAlign={'center'} fontSize='xl' fontFamily={'Roboto Condensed'} color={'#f0e6d9'}>{r.name}</Text>
                  <VStack gap={1}>
                  {r.representations.map((rep, index) => (
                      <MotionBox key={index} {...translateXAnimation(index)} position={'relative'}>
                          <CustomButton 
                          date={date2date(rep.date)} 
                          hour={date2time(rep.date)} 
                          soldOut={rep.isSoldOut} 
                          onClick={() => { if (!rep.isSoldOut) navigate(`/seats/${rep.id}`) }}
                          height={totalRepresentations > 4 ? 40 : 60}/>
                           {rep.isSoldOut &&
                          <Img src="sold-out.png"
                            position="absolute" 
                            top="0" 
                            right="0"
                            h={'60px'}
                            w={'auto'} 
                            zIndex="1"></Img>
                            }
                      </MotionBox>
                  ))}
                  </VStack>
              </Fragment>
          ))}
      </Box>

    ) : (

      <Flex width="100%" flexWrap="wrap" h={400} backgroundColor='white' overflow='hidden'  boxShadow="sm" borderRadius={7} _hover={{ boxShadow: "md" }}>
        <Box width={{ base: "100%", md: "30%" }}  >
          
          <MotionImage
            as="img"
            width={"100%"} 
            height={"100%"}
            src={buildImageUrl(show.onlineAvatar, 'm')}
            {...fadeInAnimation}/>

        </Box>
        <Box width={{ base: "100%", md: "45%" }} p="5" >
          <VStack align={'left'}>
            <MotionText  fontSize='2.2rem' noOfLines={2} key={1} {...translateYAnimation(1)} fontFamily={'Raleway'} mb={'0.5rem'}>
                {i18n.language === "ro" ? show.name?.toUpperCase(): show.nameEng?.toUpperCase()}
            </MotionText >
              <MotionText  fontSize='sm' noOfLines={2} key={2} {...translateYAnimation(2)} fontFamily={'Roboto Condensed'} fontWeight={300} mt={0}>
                {show.author}
              </MotionText >
              <MotionText  fontSize='sm' noOfLines={2} key={3} {...translateYAnimation(3)} fontFamily={'Roboto Condensed'} fontWeight={300} mt={0}>
                  {show.director}
              </MotionText >
              <MotionText  fontSize='sm' noOfLines={2} key={4} {...translateYAnimation(4)} fontFamily={'Roboto Condensed'} fontWeight={300} mt={0}>
                {t("SHOW_LENGTH")}: {show.duration}
              </MotionText >
              <MotionText  fontSize='lg' noOfLines={3} key={5} {...translateYAnimation(5)} fontFamily={'Roboto Condensed'} fontWeight={700}>
                {i18n.language === "ro" ? show.warning: show.warningEng}
              </MotionText >
              <MotionText  fontSize='lg' noOfLines={3} key={6} {...translateYAnimation(5)} fontFamily={'Roboto Condensed'} fontWeight={400} mt={0}>
                {i18n.language === "ro" ? show.description: show.descriptionEng} 
              </MotionText >
              <MotionLink href={show.detailsUrl} isExternal key={7} {...translateYAnimation(5)} fontFamily={'Roboto Condensed'} fontWeight={300} mt={0} color={'#b78716'}>
                {t("SHOW_READ_MORE")}
              </MotionLink>
          </VStack>
        </Box>
        <Box width={{ base: "100%", md: "25%" }} p={5} pr={'50px'} overflowY="auto" height='100%'
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          'msOverflowStyle': 'none',
          'scrollbarWidth': 'none'
        }}>
          {show.rooms.filter(r => r.representations.length > 0).map((r) => (
              <Fragment key={r.id}>
                  <Text fontSize='xl' fontFamily={'Roboto Condensed'}>{i18n.language === "ro" ? r.name: r.nameEng}</Text>
                  <VStack gap={1}>
                  {r.representations.map((rep, index) => (
                      <MotionBox key={rep.id} {...translateXAnimation(index)} position={'relative'}>
                          <CustomButton 
                          date={date2date(rep.date as Date)} 
                          hour={date2time(rep.date as Date)} 
                          soldOut={rep.isSoldOut} 
                          onClick={() => { if (!rep.isSoldOut) navigate(`/seats/${rep.id}`) }}
                          height={totalRepresentations > 4 ? 40 : 60}/>
                          {rep.isSoldOut &&
                          <Img src="sold-out.png"
                            position="absolute" 
                            top="0" 
                            right="0"
                            h={'60px'}
                            w={'auto'} 
                            zIndex="1"></Img>
                            }
                      </MotionBox>
                  ))}
                  </VStack>
              </Fragment>
          ))}
      </Box>

      </Flex>
    );
    
 
  };
  
  export default ShowCard;
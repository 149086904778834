import { BtRepresentationFull, BtShow} from "../../core/types";
import { useContext } from "react";
import { ApiContext } from "../../contexts/apiContext";

const useShowsRepo = () => {
    const { get } = useContext(ApiContext);

    const getShows = async () : Promise<BtShow[]> => {
        const response = await get('shop/shows');
        return response as BtShow[];
    };

    const getShow = async (id: string) : Promise<BtShow> => {
        const response = await get(`shop/shows/${id}`);
        return response as BtShow;
    };

    const getRepresentationDetails = async (id: string) : Promise<BtRepresentationFull> => {
        const response = await get(`shop/shows/representation/${id}`);
        return response as BtRepresentationFull;
    };

    const getShowBySlug = async (slug: string) : Promise<BtShow> => {
        const response = await get(`shop/shows/slug/${slug}`);
        return response as BtShow;
    };

    const getRepresentationDetailsBySlug = async (slug: string) : Promise<BtRepresentationFull> => {
        const response = await get(`shop/shows/representation/slug/${slug}`);
        return response as BtRepresentationFull;
    };

    return {getShow, getShows, getRepresentationDetails, getShowBySlug, getRepresentationDetailsBySlug}
};

export default useShowsRepo;
